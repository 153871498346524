import React, { useState } from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap"

const getImage = graphql`
  query {
    gebhart: file(relativePath: { eq: "ref/gebhart.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    jun: file(relativePath: { eq: "ref/jun.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    twin: file(relativePath: { eq: "ref/twin.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    salweb: file(relativePath: { eq: "ref/sal-web.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hausmeister: file(relativePath: { eq: "ref/hausmeister.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    salservice: file(relativePath: { eq: "ref/salservicegmbh.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    mayhem: file(relativePath: { eq: "ref/mayhem.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    luisa: file(relativePath: { eq: "ref/luisa-maerkl.png" }) {
      childImageSharp {
        fixed(width: 723) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    gebhartMobile: file(relativePath: { eq: "ref/gebhart.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    junMobile: file(relativePath: { eq: "ref/jun.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    twinMobile: file(relativePath: { eq: "ref/twin.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    salwebMobile: file(relativePath: { eq: "ref/sal-web.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    salserviceMobile: file(relativePath: { eq: "ref/salservicegmbh.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hausmeisterMobile: file(relativePath: { eq: "ref/hausmeister.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mayhemMobile: file(relativePath: { eq: "ref/mayhem.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    luisaMobile: file(relativePath: { eq: "ref/luisa-maerkl.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const items = [
  {
    src: "gebhart",
    srcMobile: "gebhartMobile",
    altText: "Rudi Gebhart",
    caption: "Rudi Gebhart",
    header: "Kunde 1 Header",
    key: "1",
    url: "https://www.rudi-gebhart.de",
  },
  {
    src: "jun",
    srcMobile: "junMobile",
    altText: "Kunde 2",
    caption: "Referenz 2",
    header: "Kunde 2 Header",
    key: "2",
    url: "https://www.junmarkl.com",
  },
  {
    src: "twin",
    srcMobile: "twinMobile",
    altText: "Twinmotion pictures",
    caption: "Twinmotion pictures",
    header: "Kunde 1 Header",
    key: "3",
    url: "https://www.twinmotion.pictures",
  },
  {
    src: "salweb",
    srcMobile: "salwebMobile",
    altText: "SAL Service GmbH",
    caption: "SAL Service GmbH",
    header: "Kunde 1 Header",
    key: "4",
    url: "https://sal-web.de/",
  },
  {
    src: "salservice",
    srcMobile: "salserviceMobile",
    altText: "SAL Service GmbH",
    caption: "SAL Service GmbH",
    header: "Kunde 1 Header",
    key: "5",
    url: "https://salservicegmbh.de/",
  },
  {
    src: "hausmeister",
    srcMobile: "hausmeisterMobile",
    altText: "Hausmeisterservice SAL Service GmbH",
    caption: "Hausmeisterservice SAL Service GmbH",
    header: "Kunde 1 Header",
    key: "6",
    url: "https://www.hausmeisterbayern.de/",
  },
  {
    src: "mayhem",
    srcMobile: "mayhemMobile",
    altText: "Mayhem Bikes",
    caption: "Mayhem Bikes",
    header: "Kunde 1 Header",
    key: "7",
    url: "https://www.mayhem-bikes.de/",
  },
  {
    src: "luisa",
    srcMobile: "luisaMobile",
    altText: "Luisa Märkl",
    caption: "Luisa Märkl",
    header: "Kunde 1 Header",
    key: "8",
    url: "https://www.luisamaimarkl.com/",
  },
]

const RefCarousel = () => {
  const image = useStaticQuery(getImage)
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const slides = items.map(item => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          <Image
            className="d-none d-lg-block"
            fixed={image[item.src].childImageSharp.fixed}
            alt={item.altText}
          />
          <Image
            className="d-lg-none"
            fluid={image[item.srcMobile].childImageSharp.fluid}
            alt={item.altText}
          />
        </a>
      </CarouselItem>
    )
  })

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  )
}

export default RefCarousel
