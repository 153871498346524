import React from "react"
import { Container } from "reactstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import styled from "styled-components"

import StyledHero from "../StyledHero"
import OutlineButton from "../OutlineButton"
import Title from "../Title"
import Deck from "./Deck"

const getBackground = graphql`
  query {
    perform: file(relativePath: { eq: "perform.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Performances = () => {
  const image = useStaticQuery(getBackground)
  return (
    <section id="performances">
      <StyledHero img={image.perform.childImageSharp.fluid} />
      <Container className="py-5">
        <ScrollAnimation animateIn="fadeIn" animateOnce>
          <Title title="Leistungen" />
          <hr />
          <Text className="text-center mb-5">
            <p>
              Wenn Sie sich für eine individuelle und professionelle Webseite
              entscheiden, dann sind Sie hier genau richtig. Ich biete Ihnen die
              neuesten Technologien für die Umsetzung Ihrer Homepage an, um
              somit kurze Ladezeiten zu gewährleisten, die optimale Ansicht auf
              allen gängigen Geräten zu ermöglichen und Ihre Seite in Google
              ganz oben zu platzieren. Gerne implementiere ich Ihnen auch bei
              Bedarf ein Content Managment System. Weiterhin können Sie zu Ihrer
              Homepage gleich eine Progressive Web App erhalten, welche ohne App
              Store installiert werden kann. Zudem biete ich Ihnen Fotografie
              und Videoproduktion bzw. Werbe-/Imagefilme nach Maß für Ihre
              persönlichen Bedürfnisse durch meine Partner "Twinmotion" an.
              <br />
              Falls Sie irgendwelche Fragen zu zu den Leistungen haben können
              Sie mich gerne unter meiner
              <a href="mailto:t.heft@heftigewebseiten.de"> Email </a> oder
              einfach über{" "}
              <a
                href="https://wa.me/message/4COQBGBD7QILD1"
                target="_blank"
                rel="noreferrer"
              >
                Whatsapp
              </a>{" "}
              kontaktieren.
            </p>
            <Link to="/#contact">
              <OutlineButton title="Unverbindlich Anfragen"></OutlineButton>
            </Link>
          </Text>
        </ScrollAnimation>
        <Deck />
      </Container>
    </section>
  )
}

const Text = styled.div`
  a {
    color: green;
    font-weight: 700;
    text-decoration: none;
  }
`

export default Performances
